import { Box, BoxProps } from '@chakra-ui/react';

const COLORS = [
  '#ff0000',
  '#ff7300',
  '#fffb00',
  '#48ff00',
  '#00ffd5',
  '#002bff',
  '#7a00ff',
  '#ff00c8',
  '#ff0000',
];

export const GlowingEffect: React.FC<{
  children?: React.ReactNode;
  /** NOTE: you'll need to set any child to `border-radius: inherit` */
  rounded: BoxProps['rounded'];
  enabled?: boolean;
}> = ({ children, rounded, enabled = true }) => {
  if (!enabled) return <>{children}</>;

  return (
    <Box rounded={rounded} position="relative" display="inline-block">
      <Box
        rounded="inherit"
        position="absolute"
        top="-2px"
        left="-2px"
        background={`linear-gradient(45deg, ${COLORS.join(', ')})`}
        backgroundSize="400%"
        zIndex={0}
        filter="blur(5px)"
        width="calc(100% + 4px)"
        height="calc(100% + 4px)"
        animation="GlowingEffect--anim 20s linear infinite"
        sx={{
          '@keyframes GlowingEffect--anim': {
            '0%': { backgroundPosition: '0 0' },
            '50%': { backgroundPosition: '400% 0' },
            '100%': { backgroundPosition: '0 0' },
          },
        }}
      />
      <Box
        position="relative"
        zIndex={1} // overtop of the gradient
      >
        {children}
      </Box>
    </Box>
  );
};
